import LayoutA3 from "../../components/layout-a3"
import React from "react"
import Login from "../../components/login"


export default class A3Login extends React.Component {

  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA3/profile/enrollInfoList"
    return(
      <LayoutA3 >
        <Login color={color} src={src} />
      </LayoutA3>
    )
  }
}